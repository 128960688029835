import moment from "moment";
import { IERCProtocolsElement } from "../types/ERCProtocolsElement";
import { IERCProtocolsCardElement } from "../types/ERCProtocolsCardElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/fields";

export const convertApiItemToUi = (
  item: IERCProtocolsElement
): IERCProtocolsElement & {
  oikShortName: string;
  status: string;
  fundNumber: string;
  userAvailableRoles: string;
  approvedDate: string;
} => {
  return {
    ...item,
    oikShortName: item.oikDto?.name,
    fundNumber: item.fundDto?.number,
    status: item.commissionStatus?.title ?? "",
    approvedDate: item?.approvedDate ? moment(item?.approvedDate).format("YYYY-MM-DD HH:mm") : "-",
    createDateTime: item?.createDateTime ? moment(item?.createDateTime).format("YYYY-MM-DD HH:mm") : "-",
    userAvailableRoles: item?.availableActions || "",
    [fields.CATEGORY]: item.category?.title || "",
    [fields.KIND]: item.kind?.title || "",
    [fields.TYPE]: item.type?.title || "",
  };
};

export const convertApiItemToCardUi = (item: IERCProtocolsCardElement): IERCProtocolsCardElement & { oikShortName: string; fundNumber: string } => ({
  ...item,
  ...convertApiItemToUi(item),
});
