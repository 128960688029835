









































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import InfoModal from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/InfoModal.vue";
import ToggleTableCellsContainer from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/ToggleTableCellsContainer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "./constants/breadcrumbs";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { module as ExpertComissionView } from "../../store/modules/ExpertComissionView";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import useGetTableLibs from "./composables/useGetTableLibs";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { convertApiItemToUi } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { fields } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/utils/defaultCurrentFilters";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

export default defineComponent({
  name: "ExpertComissionView",
  components: {
    TableSettings,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    ExportButton,
    TableHeader,
    Breadcrumbs,
    Footer,
    SortPanelContainer,
    InfoModal,
    ToggleTableCellsContainer,
    AutoRefreshButton,
    ExportFooterButton,
  },
  data() {
    return {
      breadcrumbs,
      sectionName: "ExpertComissionView", // name === store module name
      viewTitle,
      viewUniqKey,
      showEadModal: false,
      pluralFormTitles: ["Проект", "Проекта", "Проектов"],
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FILTER_NUMBER, fields.FILTER_OIK],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters("ExpertComissionView", [
      "cells",
      "data",
      "openedId",
      "selectedIds",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "isOpenFilters",
      "autorefresh",
      "isLoadingChangeAutorefresh",
    ]),
    ...mapGetters("ExpertComissionView", ["kindsLibrary", "categoriesLibrary", "typesLibrary", "funds", "statuses"]),
    fundNames(): { title: string; value: string; isDeleted: boolean }[] {
      return this.funds.map((fund: { name: string; code: string; isDeleted: boolean }) => ({
        title: fund.name,
        value: fund.code,
        isDeleted: fund.isDeleted,
      }));
    },
    statusesResultList(): { title: string; value: string }[] {
      return convertToSelectItems(this.statuses || []);
    },
    kinds(): { title: string; value: string }[] {
      return convertToSelectItems(this.kindsLibrary, "title", "code");
    },
    types(): { title: string; value: string }[] {
      return convertToSelectItems(this.typesLibrary, "title", "code");
    },
    categories(): { title: string; value: string }[] {
      return convertToSelectItems(this.categoriesLibrary, "title", "code");
    },
    availableAction(): { title: string; value: string }[] {
      return convertToSelectItems(["Формирование комиссии", "Согласование", "Утверждение"]);
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        fundNames: this.fundNames,
        statusesResultList: this.statusesResultList,
        kinds: this.kinds,
        types: this.types,
        categories: this.categories,
        availableAction: this.availableAction,
      };
    },
    isShowExport(): boolean {
      //return isAuthorityExist(this.user, authorities.ERC_EXPORT);
      return true;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId) && this.data.find((element: { id: number }) => element.id == this.openedId);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isShowFooter(): boolean {
      return this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length;
    },
    additionalClassObj() {
      return (index: number, event: Record<string, unknown>) => {
        return {
          "monitoring-table__td_first": !index,
          "monitoring-table__td_success": !index && event.status === "Полностью согласован",
          "monitoring-table__td_error": !index && event.status !== "Полностью согласован",
        };
      };
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch("ExpertComissionView/setIsSelectAll", value);
      },
    },
  },
  methods: {
    ...mapActions("ExpertComissionView", ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "setAutorefresh"]),
    async getExportDataCb() {
      await this.getExportData("PDF");
    },
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          this.$store.dispatch("ExpertComissionView/addOpenedId", event.id);
          this.toggleModal(true);
          break;
        case fields.OIK_SHORT_NAME: {
          this.moveByAuthorities(header, "/dictionaries/oik", {
            name: event[fields.OIK_SHORT_NAME] as string,
            isOpenModal: "1",
          });
          break;
        }

        case fields.FUND_NUMBER: {
          this.moveByAuthorities(header, "/dictionaries/fund", {
            number: event[fields.FUND_NUMBER] as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch("ExpertComissionView/addOpenedId", null);
      }
    },
    setCells(value: Record<string, unknown>[]) {
      this.$store.dispatch(
        "ExpertComissionView/setCells",
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, "ExpertComissionView", ExpertComissionView as Module<unknown, unknown>);
    useSetStartCells(root, "ExpertComissionView", Sections.INVENTORY_PROJECT, tableHeaders, true).then(() => {
      useRefreshTable(root, "ExpertComissionView");
    });
    useSetStartFilters(root, "ExpertComissionView", ["id", "number"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, "ExpertComissionView");
    });
    useGetTableLibs(root);
    useAsyncExport(root, "ExpertComissionView");

    return {
      filtersLoaded,
      moveByAuthorities,
    };
  },
});
