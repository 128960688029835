import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch("ExpertComissionView/getFunds");
    store.dispatch("ExpertComissionView/getKinds");
    store.dispatch("ExpertComissionView/getCategories");
    store.dispatch("ExpertComissionView/getTypes");
    store.dispatch("ExpertComissionView/getStatuses");
  });
};

export default useGetTableLibs;
