import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/fields";

export const convertFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  convertAutocompleteItemToApi(resultFilters, fields.OIK_SHORT_NAME, `fieldFilters.${fields.OIK_SHORT_NAME}.name`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_NUMBER, `fieldFilters.${fields.FILTER_NUMBER}.number`);

  formDateFilter(resultFilters, "createDateTime");

  if (resultFilters.sort?.[fields.FUND_NUMBER]) {
    (resultFilters.sort as { [key: string]: unknown })["fundCode"] = resultFilters.sort?.[fields.FUND_NUMBER];
    delete resultFilters.sort?.[fields.FUND_NUMBER];
  }
  if (resultFilters.sort?.[fields.OIK_SHORT_NAME]) {
    (resultFilters.sort as { [key: string]: unknown })["oikCode"] = resultFilters.sort?.[fields.OIK_SHORT_NAME];
    delete resultFilters.sort?.[fields.OIK_SHORT_NAME];
  }
};
