import { Module } from "vuex";
import store from "@monorepo/app/src/store";
import { IRootState } from "@monorepo/expertReviewCommission/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/utils/convertFiltersToApi";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { IUser } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IUser";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IDocument } from "@monorepo/inventory/src/views/EadView/types/IDocument";
import axios, { AxiosResponse } from "axios";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IResponse } from "@monorepo/utils/src/api/types/response";

interface ITableFiltersObjectResult extends ITableFiltersObject {
  inventoryDocumentsCells: string[];
}

interface ITableState {
  filters: ITableFiltersObjectResult;
  totalLength: number;
  data: IEadElement[];
  infiniteId: string;
  sectionCells: ISection;
  inventoryCells: string[];
  isOpenFilters: boolean;
  autorefresh: boolean;
  cells: string[];
  section: string;
  documentList: IDocument;
  searchTemplates: ISearchTemplate[];
  libraries: {
    funds: string[];
    kinds: { code: string; name: string }[];
    categories: { code: string; name: string }[];
    types: { code: string; name: string }[];
    statuses: string[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObjectResult => ({
  sort: {},
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  inventoryDocumentsCells: [],
  fieldFilters: {},
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_EXPERT_COMMISSION_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_EXPERT_COMMISSION_XLS_LIST;
    default:
      return QUERY_PATH.GET_EXPERT_COMMISSION_PDF_LIST;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    totalLength: 0,
    inventoryCells: [],
    searchTemplates: [],
    isOpenFilters: false,
    autorefresh: false,
    documentList: {} as IDocument,
    sectionCells: {} as ISection,
    section: Sections.INVENTORY_PROJECT,
    data: [],
    cells: [],
    libraries: {
      statuses: [],
      funds: [],
      kinds: [],
      categories: [],
      types: [],
    },
    isTableLoading: false,
    isLoadingToggleFilters: false,
    isLoadingChangeAutorefresh: false,
  }),
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    funds(state: ITableState, payload: string[]) {
      state.libraries.funds = payload;
    },
    setCells(state: ITableState, payload: string[]) {
      state.inventoryCells = payload;
    },
    setDocumentsTableCells(state: ITableState, payload: string[]) {
      state.filters.inventoryDocumentsCells = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    setDocumentList(state: ITableState, payload: { data: IDocument }) {
      state.documentList = payload.data;
    },
    addData(
      state: ITableState,
      payload: {
        data: IEadElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addKindsLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.kinds = payload || [];
    },
    addCategoriesLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.categories = payload || [];
    },
    addTypesLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.types = payload || [];
    },
    statuses(state: ITableState, payload: string[]) {
      state.libraries.statuses = payload;
    },
  },
  actions: {
    ...baseActions,
    async getSignatures(info, payload: string) {
      const { data } = await getQuery<any>(`${QUERY_PATH.GET_DIGITAL_SIGNATURES}/${payload}`);
      return data || {};
    },
    async getCaseTreeData(info, { id }) {
      const { data } = await getQuery<any[]>(`${QUERY_PATH.GET_CASE_ERC_HIERARCHY}/${id}`).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || {} };
      }

      return { data: {} };
    },
    async getEadTreeData(info, { length, id }) {
      const params = {
        offset: length,
        limit: 50,
      };

      const { data, total: totalLength } = await getQuery<any[]>(`${QUERY_PATH.GET_EAD_FOR_COMMISION_BY_CASE_TREE_LIST}/${id}`, params).finally(
        () => {
          info.commit("setTableLoading", false);
        }
      );

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    setDocumentsTableCells({ commit }, payload: string[]) {
      commit("setDocumentsTableCells", payload);
    },
    async deleteCommissionFile(info, file: { uuid: string }) {
      await axios.delete(`${QUERY_PATH.DELETE_COMISSION_FILE}/${file.uuid}`);
    },
    async getStatuses(info) {
      checkExistLibrary(info.state.libraries.statuses, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_COMISSION_FILTER_STATUSES);
        info.commit("statuses", data || []);
      });
    },
    async applyApprovingForm(info, { payload }: { payload: FormData }) {
      try {
        await axios.post(`${QUERY_PATH.FINAL_APPROVE_COMISSION}`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return true;
      } catch (e) {
        if (e.response?.data?.message) {
          showNotification(e.response.data.message);
        }
        console.error(e);
        return false;
      }
    },
    async getInvAutocompleteByNumber(info, value: string) {
      const { data } = await getQuery<IAutocompleteElement & { header: string; id: string; number: string }[]>(
        QUERY_PATH.GET_COMISSION_FILTER_NUMBER_AUTOCOMPLETE_ITEMS,
        {
          keyword: value,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement & { header: string; id: string; number: string }) => ({
          id: item.id,
          number: item.number,
          titleValue: item.number,
        }));
      }
      return [];
    },

    async applyCoordinatingForm(info, { payload }: { payload: FormData; coordinatorId: string; id: string }) {
      try {
        await axios.post(`${QUERY_PATH.COORDINATE_EPC}`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return true;
      } catch (e) {
        if (e.response?.data?.message) {
          showNotification(e.response.data.message);
        }
        console.error(e);
        return false;
      }
    },
    async blockComission(info, formData) {
      try {
        await axios.post(`${QUERY_PATH.BLOCK_COMISSION}`, formData);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async approveCommissionList(
      info,
      payload: {
        inventoryProjectId: number;
        commissionMemberList: {
          personId: number;
          memberKind: string;
        }[];
      }
    ) {
      try {
        await axios.post(QUERY_PATH.CREATE_EPC, payload);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IEadElement[]>(QUERY_PATH.GET_EXPERT_COMMISSION_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getInventoryElement(info, payload: string) {
      const { data } = await getQuery<IEadElement>(`${QUERY_PATH.GET_EXPERT_COMMISSION_LIST}/${payload}`, { withCases: false });
      return data || {};
    },
    async getUsersList(info, params: { authority: number; fullname: string; offset: number; limit: number }) {
      const { authority, ...additionalParams } = params;
      const { data, total: totalLength } = await getQuery<IUser[]>(
        `${QUERY_PATH.GET_AUTHORITY_USER_LIST}/${authority}/users-for-commission`,
        additionalParams,
        false
      );
      return { data, totalLength } || { data: [], totalLength: 0 };
    },
    async saveCard(): Promise<boolean> {
      try {
        //const { openedId, comment, historicalNote } = data;
        // await axios.put(getFullPath(QUERY_PATH.SAVE_INVENTORY_COMMENT), { entityId, comment }).catch(function (error) {
        //   showNotification(error.response.data.message);
        //   return Promise.reject(error);
        // });
        // const promiseComment = ;
        // const promiseHistoricalNote = ;

        return true;
      } catch (e) {
        return false;
      }
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IEadElement[]>(QUERY_PATH.GET_EXPERT_COMMISSION_LIST, { ...params, offset: 0 });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getDocumentList({ commit }, id) {
      const { data } = await getQuery<IDocument>(`${QUERY_PATH.GET_INVENTORIES_LIST}${id}/sections`);
      if (data !== null) {
        commit("setDocumentList", { data: data || {} });
        return { data: data || {} };
      }
      return { data: null };
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      if (payload !== "PDF") {
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
          root: true,
        });
      } else {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(QUERY_PATH.GET_EXPERT_COMMISSION_PDF_LIST_ASYNC, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
      }
    },
    getFunds({ commit, state }) {
      checkExistLibrary(state.libraries.funds, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_FUND_SHORT_ITEM_LIB);
        commit("funds", data || []);
      });
    },
    getKinds({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.kinds, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(getFullPath(QUERY_PATH.GET_INVENTORIES_KINDS));
          commit("addKindsLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка типов описи.");
      }
    },
    getCategories({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.categories, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(
            getFullPath(QUERY_PATH.GET_INVENTORIES_CATEGORIES)
          );
          commit("addCategoriesLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка видов описи.");
      }
    },
    getTypes({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.types, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(getFullPath(QUERY_PATH.GET_INVENTORIES_TYPES));
          commit("addTypesLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка типов хранения.");
      }
    },
  },
  getters: {
    ...baseGetters,
    cells(state: ITableState) {
      return state.inventoryCells ?? [];
    },
    documentsTableCells(state: ITableState) {
      return state.filters?.inventoryDocumentsCells ?? [];
    },
    documentList(state: ITableState) {
      return state.documentList ?? {};
    },
    funds(state: ITableState) {
      return state.libraries.funds || [];
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    kindsLibrary(state: ITableState) {
      return state.libraries.kinds || [];
    },
    categoriesLibrary(state: ITableState) {
      return state.libraries.categories || [];
    },
    typesLibrary(state: ITableState) {
      return state.libraries.types || [];
    },
    statuses(state: ITableState) {
      return state.libraries.statuses || [];
    },
  },
};
