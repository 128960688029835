import { fields } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.FILTER_NUMBER]: {},
  [fields.FILTER_OIK]: {},
  [fields.FILTER_FUND]: [],
  [fields.FILTER_STATUS]: [],
  [fields.FILTER_KIND]: [],
  [fields.FILTER_TYPE]: [],
  [fields.FILTER_CATEGORY]: [],
  [fields.FILTER_CREATE_DATE_TIME_FROM]: "",
  [fields.FILTER_CREATE_DATE_TIME_TO]: "",
});
