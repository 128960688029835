var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backdrop",on:{"click":_vm.closeModal}},[_c('FormulateForm',{attrs:{"name":"erc-protocols-info-modal"},on:{"submit":_vm.onSave},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('div',{staticClass:"info-modal d-flex align-center justify-end",on:{"click":function($event){$event.stopPropagation();}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading && _vm.isShowAnimation)?_c('TmpLogoOverlay',{staticClass:"absolute"}):_vm._e()],1),_c('div',{class:[
          'info-modal__content d-flex flex-column',
          {
            'info-modal__content_three-quarter': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER,
            'info-modal__content_full': _vm.cardModeResult === _vm.cardMode.FULL,
          } ]},[_c('card-modal-toggle-view-size',{attrs:{"section":_vm.section}}),_c('div',{staticClass:"info-modal__close-wrap"},[_c('v-btn',{attrs:{"data-test-id":"closeBtn","icon":""},nativeOn:{"click":function($event){return _vm.closeModal($event)}}},[_c('v-icon',{attrs:{"color":"#21201F"}},[_vm._v("mdi-close")])],1)],1),(_vm.isLoading)?_c('TmpBlock',{staticClass:"info-modal__title ml-6 mr-6 mb-4 mt-12",attrs:{"height":"24"}}):[_c('div',{staticClass:"info-modal__title pl-6 pr-6 pb-4 pt-12",attrs:{"data-test-id":"header"}},[_c('card-modal-toggle-table-items',{on:{"toggle":_vm.changeOpenedId}}),_c('span',{staticClass:"ml-2"},[_vm._v(" Карточка проекта описи дел: "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" «"+_vm._s(_vm.element.header)+"»")])]}}])},[_c('div',[_vm._v(_vm._s(_vm.element.header))])])],1)],1)],_c('div',{staticClass:"info-modal__content-wrap"},[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":"","value":_vm.openedPanels},on:{"update:value":function($event){_vm.openedPanels=$event}}},[_c('CardModalChapter',{attrs:{"title":"Общая информация","modalElements":_vm.resultModalElements,"isLoading":_vm.isLoading,"formValues":_vm.element,"modalType":_vm.type},on:{"clickElement":_vm.clickElementCb}}),_c('CardModalChapter',{attrs:{"title":"Дела","isLoading":_vm.isLoading}},[_c('keep-alive',[(_vm.isLoading)?_c('div',_vm._l((5),function(item){return _c('TmpBlock',{key:item,staticClass:"mt-1 mb-1",attrs:{"height":"24"}})}),1):_vm._e(),_c('CaseTableTree',{attrs:{"cardModeResult":_vm.cardModeResult,"isLoading":_vm.isLoading,"id":_vm.element.id}})],1)],1),(_vm.isShowCommission)?_c('comission-wrapper',{attrs:{"isLoading":_vm.isLoading,"element":_vm.element},on:{"onCreateCommision":_vm.handleCreateCommision}}):_vm._e()],1)],1),_c('div',{staticClass:"pa-6 info-modal__footer"},[_c('ExportBtn',{attrs:{"ml":""},on:{"export":_vm.openCardPdf}}),(_vm.isShowProtocolExport)?_c('ExportBtn',{attrs:{"ml":"","title":"Скачать протокол ЭПК","tooltip":"Скачать протокол ЭПК"},on:{"export":_vm.openProtocolPdf}}):_vm._e()],1)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }